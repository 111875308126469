const cnfsdTabbableContent = {
	init: () => {
		cnfsdTabbableContent.setupTabs();
	},

	setupTabs: () => {
		// Get all tabbed sections on the page
		const tabSections = document.querySelectorAll('.mod-tabbable-content');

		let tabButtons, tabContents, nav, navContainer, leftArrow, rightArrow;

		// Iterate through each tabbed section
		tabSections.forEach(section => {
			// Get tab buttons and tab contents within the current section
			nav = section.querySelector('.mod-tabbable-content__nav');
			navContainer = nav.querySelector('.mod-tabbable-content__nav-list');
			tabButtons = section.querySelectorAll('.mod-tabbable-content__nav-button');
			(tabContents = section.querySelectorAll('[data-tabbableContent]')),
				(leftArrow = nav.querySelector('.mobile-nav__arrow--left')),
				(rightArrow = nav.querySelector('.mobile-nav__arrow--right'));

			// Set the first tab as active on page load
			tabButtons[0].classList.add('active');

			// Show the first tab on page load
			tabContents.forEach((content, index) => {
				index === 0
					? content.classList.add('initialised')
					: content.classList.add('display-none', 'initialised');
			});

			// Handle tab clicks and show hide content
			cnfsdTabbableContent.handleTabClicks(tabButtons, tabContents);
			cnfsdTabbableContent.watchNavScroll(tabButtons, leftArrow, rightArrow);
			cnfsdTabbableContent.handleNavScrollButtons(navContainer, leftArrow, rightArrow);
		});
	},

	removeClasses: (elementGroup, classname) => {
		elementGroup.forEach(elem => elem.classList.remove(classname));
	},

	handleTabClicks: (tabButtons, tabContents) => {
		// Add click event listener to each tab button within the current section
		tabButtons.forEach((button, index) => {
			button.addEventListener('click', event => {
				event.preventDefault();
				cnfsdTabbableContent.removeClasses(tabButtons, 'active');
				button.classList.add('active');
				// Hide all tab contents within the current section
				tabContents.forEach(content => {
					content.classList.add('display-none');
				});
				// Show the clicked tab content within the current section
				tabContents[index].classList.remove('display-none');
			});
		});
	},

	watchNavScroll: (tabButtons, leftArrow, rightArrow) => {
		// Options for the Intersection Observer
		const options = {
			root: null, // Use the viewport as the root
			rootMargin: '0px',
			threshold: 0.9, // SET TO .9 TO AVOID ISSUES WITH MARGINS
		};

		const handleIntersection = (entries, observer) => {
			entries.forEach(entry => {
				if (entry.target === tabButtons[0]) {
					// IF FIRST BUTTON IN NAV LIST IS VISIBLE ON SCREEN, HIDE LEFT BUTTON
					if (entry.isIntersecting) {
						leftArrow.classList.add('display-none');
					} else {
						leftArrow.classList.remove('display-none');
					}
				}

				console.log(entry.target);

				if (entry.target === tabButtons[tabButtons.length - 1]) {
					// IF LAST BUTTON IN NAV LIST IS VISIBLE ON SCREEN, HIDE RIGHT BUTTON
					if (entry.isIntersecting) {
						rightArrow.classList.add('display-none');
					} else {
						rightArrow.classList.remove('display-none');
					}
				}
			});
		};

		// Create an Intersection Observer instance
		const observer = new IntersectionObserver(handleIntersection, options);

		// Observe each button
		tabButtons.forEach(button => {
			observer.observe(button);
		});
	},

	handleNavScrollButtons: (navContainer, leftArrow, rightArrow) => {
		const scrollHandler = event => {
			event.preventDefault();
			cnfsdTabbableContent.scrollNav(navContainer, event);
		};

		leftArrow.addEventListener('click', scrollHandler);
		rightArrow.addEventListener('click', scrollHandler);
	},

	scrollNav: (navContainer, event) => {
		if (event.target.classList.contains('mobile-nav__arrow--left')) {
			navContainer.scrollBy({
				left: navContainer.scrollWidth * -1,
				behavior: 'smooth',
			});
		} else {
			navContainer.scrollBy({
				left: navContainer.scrollWidth,
				behavior: 'smooth',
			});
		}
	},
};

document.addEventListener('DOMContentLoaded', () => {
	const tabs = document.querySelectorAll('.mod-tabbable-content');
	if (tabs && tabs.length > 0) {
		cnfsdTabbableContent.init();
	}
});
